.categoriesResults {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.categoriesResults__videocard > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.categoriesResults__player {
  height: 100%;
  width: 100%;
}

.categoriesResults__player__yt {
  height: 100vh;
  width: 100%;
}

.playerVideo__videoCard {
  color: white;
  width: 400px;
  height: 100%;
  transition: transform 400ms ease-in-out;
  background-color: black;
  padding: 20px 0;
}

.playerVideo__videoCard:hover {
  transform: scale(1.05);
}

.playerVideo__videoCard__img {
  border-radius: 12px;
  display: block;
  margin: 0 auto;
  max-width: 90%;
  height: auto;
  cursor: pointer;
  padding-bottom: 5px;
}

.playerVideo__videoCard > p {
  width: 80%;
  padding-top: 5px;
  padding-left: 20px;
}

.playerVideo__videoCard > h2 {
  padding-top: 10px;
  padding-left: 20px;
  width: 80%;
}

.playerVideo__videoCard__title {
  width: 100%;
  font-weight: 400;
  padding-left: 20px;
  font-size: 1.2rem;
}

.playerVideo__videoCard__stats {
  display: none;
}

.playerVideo__videoCard:hover > .videoCard__stats {
  display: inline;
}

.playerVideo__videoCard__description {
  display: none;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
