* {
  margin: 0;
}

.homeScreen {
  /* background-color: #111; */
  height: 100%;
}

.homescreen__navbottom {
  display: flex;
  background-color: var(--demoqrata-four);
  padding: 10px 20px;
  position: sticky;
  bottom: 0;
  z-index: 99;
  /* border-top: 1px solid var(--demoqrata-three); */
}

.homescreen__navbottom__box {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}

.homescreen__navbottom__msg {
  font-size: 1rem;
  font-weight: 400;
}

.homescreen__navbottom__button {
  font-size: 1rem;
  font-weight: 400;
  margin-left: 20px;
  padding: 5px 20px;
  color: var(--demoqrata-zero);
  background-color: var(--demoqrata-five);
  border: solid 0.5px var(--demoqrata-zero);
  border-radius: 20px;
  cursor: pointer;
}

.homescreen__search {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid var(--demoqrata-three);
  height: 10px;
  padding: 10px 10px;
  border-radius: 999px;
  margin: 0 auto;
  width: 100%;
  color: whitesmoke;
}

.homescreen__search:hover {
  background-color: lightgray;
  opacity: 1;
  transition: 1s;
  color: var(--demoqrata-zero);
}

.homescreen__search form {
  display: flex;
  max-width: 1200px;
}

.homescreen__search form > input {
  flex: 1;
  padding: 0px 10px;
  font-size: medium;
  border: none;
  background: none;
  color: var(--demoqrata-zero);
  opacity: 0.8;
}

.homescreen__search form > input:hover {
  color: var(--demoqrata-four);
}

.homescreen__search form > input:hover::placeholder {
  color: var(--demoqrata-four);
}

.homescreen__search form > input:focus {
  outline-width: 0;
}

.homescreen__search form > input::placeholder {
  color: var(--demoqrata-zero);
}

::-webkit-input-placeholder {
  opacity: 0.75;
}

.homescreen__searchButton {
  background: none;
  border: none;
  color: white;
  font-size: 2em;
  opacity: 0.75;
  padding-top: 5px;
}

.homescreen__searchButton:focus {
  outline: none;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: var(--demoqrata-four);
  font-weight: bold;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--demoqrata-four);
  font-weight: bold;
}

:-ms-input-placeholder {
  color: var(--demoqrata-four);
  font-weight: lighter;
}