.errorScreen {
    color: white;
    height: 100vh;
    width: 100%;
}

.errorScreen__content {
    display: flex;
    margin: 0;
}

.errorScreen__msg {
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 40px;
    width: 50%;
    margin: auto 0;
    align-items: center;
}

.errorScreen__msg__t1 {
    font-size: 3rem;
    padding: 20px 0;
    text-align: center;
    font-weight: 600;
    color: var(--demoqrata-three);
}

.errorScreen__msg__t2 {
    opacity: 0.8;
    font-size: 1.4rem;
    text-align: center;
    color: var(--demoqrata-three);
}

.errorScreen__msg__t3 {
    padding: 20px 0;
    text-align: center;
    font-size: 1.8rem;
    color: var(--demoqrata-three);
}

.errorScreen__msg__t4 {
    padding: 10px 0 20px 0;
    text-align: center;
    font-size: 1rem;
    color: var(--demoqrata-three);
}

.errorScreen__logo {
    display: flex;
    width: 200px;
    padding: 60px 0;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.errorScreen__msg button {
    border: solid 2px var(--demoqrata-three);
    background-color: var(--demoqrata-four);
    color: var(--demoqrata-zero);
    font-size: 1.2rem;
    font-weight: 400;
    padding: 20px;
    border-radius: 20px;
    margin: 5px 0;
    cursor: pointer;
    transition: all 0.5s ease;
}

.errorScreen__msg button:hover {
    background-color: var(--demoqrata-three);
    transition: all 0.5s ease;
}

.errorScreen__logo img {
    margin: 0 auto;
}