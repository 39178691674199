.navbottom__icons {
  color: white;
  margin: 0;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  will-change: transform;
  transform: translateZ(0);
  display: flex;
  height: 60px;
  box-shadow: 0 -2px 5px -2px #333;
  background-color: rgba(17, 17, 17, 0.95);
  overflow-y: hidden;
  overflow-x: auto;
}

.navbottom__icon {
  flex-grow: 1;
  text-align: center;
  font-size: small;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5px;
}

.navbottom__icon:hover {
  color: orange;
  opacity: 0.5;
  transition: 0.5s;
}

.navbottom__icon--active {
  color: orange;
}

.navbottom__icon-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* .navbottom__icons::-webkit-scrollbar {
  display: none;
} */

/* Hide scrollbar for IE, Edge and Firefox */
/* .navbottom__icons {
  -ms-overflow-style: none;
  scrollbar-width: none;
} */

/* Phones and Tablet*/
@media (max-width: 600px) {
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (min-width: 992px) {
  .navbottom__icons {
    display: none;
  }
  .navbottom__icon {
    display: none;
  }
  .navbottom__icon--active {
    display: none;
  }
  .navbottom__icon-content {
    display: none;
  }
}

/* Desktop */
@media (min-width: 1200px) {
  .navbottom__icons {
    display: none;
  }
  .navbottom__icon {
    display: none;
  }
  .navbottom__icon--active {
    display: none;
  }
  .navbottom__icon-content {
    display: none;
  }
}
