.keywordsVideos {
  flex: 0.8;
}

.keywordsVideos__msg {
  padding: 40px 0;
  align-items: center;
  justify-content: center;
  display: flex;
}

.keywordsVideos__msg > h2 {
  font-size: 1.5rem;
  font-weight: 400;
}

.keywordsVideos__msg h2 > span {
  font-size: 1.8rem;
  font-weight: 600;
}

.keywordsVideos__videocard > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
