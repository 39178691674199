* {
  padding: 0;
  margin: 0;
}

.donation__frame {
  width: 100%;
  height: 100%;
  display: flex;
  background: url("https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fdemoqrata_donation_before_hd.png?alt=media&token=4e9fe294-cb63-46c4-9f24-5f2da8309011")
    no-repeat center;
  transition: 0.5s;
}

.hidden {
  display: none;
}

.donation__frame:hover {
  background: url("https://firebasestorage.googleapis.com/v0/b/demoqrata-com.appspot.com/o/resources%2Fdemoqrata_donation_after_hd.png?alt=media&token=9256064b-a77e-4191-a1d8-9b1b0b9a3c0e")
    no-repeat center;
  transition: 0.8s ease-in-out;
}

.donation__contents {
  width: 45%;
  border-left: 0.5px solid #b2b2b2;
  margin-left: auto;
  margin-right: 0;
  opacity: 0.8;
  background-color: #b2b2b2;
  border-radius: 50px 0 0 50px;
  padding: 0 40px;
  z-index: 99;
  height: auto;
}

.donation__contents:hover {
  opacity: 1;
  transition: 0.5s ease-in-out;
}

.donation__contents > h2:hover {
  opacity: 1;
  transition: 0.5s;
}

.pro__donation {
  padding: 20px;
}

.pro__donation > h2 {
  font-weight: 600px;
  font-size: 1.5rem;
  padding: 20px 0;
  color: black;
}

.pro__donation__content > p {
  font-weight: 400px;
  font-size: 1.2rem;
  padding-bottom: 15px;
  max-width: 80%;
  color: black;
}

.pro__donation__sug {
  padding-top: 10px;
  padding-bottom: 30px;
}

.pro__donation__sug > p {
  font-weight: 400px;
  font-size: 1rem;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 80%;
  color: black;
}

.pro__donation__sug ul li {
  display: flex;
}

.pro__donation__sug ul li > p {
  padding-left: 10px;
  color: #111;
}

.react-tabs__tab--selected {
  background: var(--demoqrata-one);
  border-color: var(--demoqrata-one);
  color: whitesmoke;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab-list {
  border-bottom: 0.5px solid var(--demoqrata-one);
  margin: 0 0 10px;
  padding: 0;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  /* .donation__frame {
    width: 100%;
    height: 100vh;
    display: flex;
    transition: 0.5s;
  }

  .donation__contents {
    width: 100%;
    height: 750px;
    opacity: 0.9;
    border-radius: 0;
    display: block;
  }

  .pro__donation > h2 {
    font-weight: 400px;
    font-size: 1.2rem;
    padding: 15px 0;
  }

  .pro__donation__content > p {
    font-weight: 300px;
    font-size: 1rem;
    padding-bottom: 10px;
    max-width: 80%;
  }

  .pro__donation__sug {
    padding-top: 10px;
    padding-bottom: 25px;
  }

  .pro__donation__sug > p {
    font-weight: 400px;
    font-size: 1rem;
    padding-top: 10px;
    padding-bottom: 10px;
    max-width: 80%;
    color: black;
  } */
}

/* Tablet and small laptop */
@media (max-width: 799px) {
  .donation__frame {
    width: 100%;
    height: 100%;
    display: flex;
    transition: 0.5s;
  }

  .donation__contents {
    width: 100%;
    height: 750px;
    opacity: 0.9;
    border-radius: 0;
    display: block;
  }

  .pro__donation > h2 {
    font-weight: 400px;
    font-size: 1.2rem;
    padding: 15px 0;
  }

  .pro__donation__content > p {
    font-weight: 300px;
    font-size: 1rem;
    padding-bottom: 10px;
    max-width: 80%;
  }

  .pro__donation__sug {
    padding-top: 10px;
    padding-bottom: 25px;
  }

  .pro__donation__sug > p {
    font-weight: 400px;
    font-size: 1rem;
    padding-top: 10px;
    padding-bottom: 10px;
    max-width: 80%;
    color: black;
  }
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
