.headline__contents {
  position: -webkit-sticky; /* Safari */
  display: flex;
  position: sticky;
  top: 0;
  background-color: #390072;
  color: gainsboro;
  font-size: 0.9rem;
  justify-content: center;
  text-align: center;
  align-self: flex-start;
  padding: 5px 0;
  opacity: 0.9;
  display: flex;
}

.headline__contents > div {
  display: flex;
}

.dot_svg {
  width: 15px;
  height: 15px;
  justify-items: center;
  align-items: center;
  margin: 0 5px;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .headline__contents .headline__contents__signal {
    display: none;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  .headline__contents__signal {
    display: none;
  }
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
