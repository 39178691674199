.signin__banner {
  position: relative;
  z-index: 0;
  height: 100vh;
  width: 100%;
  display: flex;
}

.signin__signin {
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
  background-color: rgba(17, 17, 17, 0.8);
  border-radius: 20px;
  padding: 40px;
  max-width: 600px;
  max-height: 600px;
}

.signin__signin__msg {
  display: flex;
}

.signin__signin__msg > p {
  font-size: 1.4rem;
  align-items: center;
  margin: auto;
}

.signin__signin__msg > img {
  width: 200px;
  padding: 0 10px;
  cursor: pointer;
}

.signin__signin form {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}

.signin__signin form > input {
  padding: 12px 10px;
  outline-width: 0;
  height: 12px;
  border: none;
  max-width: 220px;
  border-radius: 5px;
  margin: 0 2px;
  font-size: 1rem;
}

.signin__signin form input::placeholder {
  color: #111;
  font-size: 1rem;
  align-items: center;
  margin: auto;
}

.signin__signin form > button {
  padding: 10px 10px;
  margin: 0 5px;
  font-size: 1.2rem;
  color: #fff;
  border-radius: 5px;
  background-color: var(--demoqrata-three);
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease;
}

.signin__signin form > button:hover {
  background-color: var(--demoqrata-one);
}

.signin__signin__help {
  display: flex;
  flex-direction: column;
}

.signin__help__signin {
  display: flex;
  padding-bottom: 20px;
  text-align: center;
  justify-content: center;
  margin: auto;
}

.signin__help__signin > p {
  font-size: 1.2rem;
  padding: 0 10px;
  align-items: center;
  margin: auto 0;
}

.signin__help__signin > span {
  cursor: pointer;
  opacity: 0.8;
  font-size: 1.2rem;
  align-items: center;
  margin: auto 0;
}

/* Phones and Tablet*/

@media (max-width: 799px) {

  .signin__signin {
    height: auto;
  }

  .signin__signin__msg {
    flex-direction: column;
  }

  .signin__signin__msg > p {
    margin-bottom: 20px;
  }

  .signin__signin form {
    flex-direction: column;
    margin: 0;
    padding: 40px 0;
  }

  .signin__signin form input {
    margin: 10px 0;
  }

  .signin__signin form button {
    margin: 10px 0;
  }

  .signin__signin__help {
    flex-direction: column;
  }

  .signin__help__signin {
    flex-direction: column;
  }

  .signin__help__signin > span {
    padding-top: 10px;
  }
}

/* Desktop */
@media (min-width: 800px) {
}
