.categories__nav {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  align-self: flex-start;
  z-index: 99;
  background-color: #111;
  display: flex;
  color: white;
  overflow-x: scroll;
  padding: 80px 20px 20px 20px;
  justify-content: left;
  transition: transform 50ms ease;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.categories__nav::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.categories__nav {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.categories__nav > h2 {
  font-size: 1.4rem;
  cursor: pointer;
  margin-left: 30px;
  margin-right: 30px;
  font-weight: 500;
  opacity: 0.7;
  transition: transform 50ms ease-in-out;
}

.categories__nav > h2:hover {
  opacity: 1;
  transform: scale(1.2);
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .categories__nav {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    align-self: flex-start;
    z-index: 99;
    background-color: #111;
    display: flex;
    color: white;
    overflow-x: scroll;
    padding-top: 30px;
    padding-right: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
    justify-content: left;
    transition: transform 50ms ease;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
