:root {
  --bg: var(--demoqrata-three);
  --bg-accent: black;
  --text-color: #dadce1;
  --nav-size: 35px;
  --border: 1px solid #474a4d;
  --border-radius: 20px;
  --speed: 500ms;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

/* Top Navigation Bar */

/* <nav> */
.navbar {
  height: var(--nav-size);
  background-color: var(--bg);
  padding: 0 1rem;
  border-bottom: var(--border);
}

/* <ul> */
.navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

/* <li> */
.nav-item {
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Icon Button */
.icon-button {
  --button-size: calc(var(--nav-size) * 0.5);
  /* width: var(--button-size);
  height: var(--button-size); */
  /* background-color: var(--demoqrata-one); */
  border-radius: 50%;
  margin: auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.icon-button:hover {
  filter: brightness(1.2);
}

.icon-button svg {
  fill: var(--text-color);
  width: 25px;
  height: 25px;
  margin: 0 10px;
}

/* Dropdown Menu */

.dropdown {
  position: absolute;
  top: 60px;
  width: auto;
  
  transform: translateX(-45%);
  background-color: var(--bg);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1rem;
  overflow: hidden;
  transition: height var(--speed) ease;
  box-shadow: rgba(255, 255, 255, 0.3) 0px 8px 24px;
}

.menu {
  width: 100%;
  height: auto;
}

.menu-item {
  height: auto;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  margin: 10px 0;
}

.menu-item .icon-button {
  margin-right: 0.7rem;
}

.menu-item .icon-button:hover {
  filter: none;
}

.menu-item:hover {
  background-color: #111;
}

.icon-right {
  margin-left: auto;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}

.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}

.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}

/* .dropdown__copyright {
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-weight: 200;
  padding: 20px;
} */

.dropdown__username {
  font-size: 1rem;
  border: 1px solid var(--demoqrata-zero);
  padding: 10px;
  border-radius: 50px;
  color: var(--demoqrata-zero);
}

.dropdown__username p {
  text-align: center;
}

.dropdown__search {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgray;
  height: 10px;
  padding: 10px;
  border-radius: 999px;
  margin: 0;
  width: auto;
  color: whitesmoke;
}

.dropdown__search:hover {
  background-color: lightgray;
  opacity: 1;
  transition: 1s;
  color: var(--demoqrata-zero);
}

.dropdown__search form {
  display: flex;
  width: 100%;
}

.dropdown__search form > input {
  flex: 1;
  padding: 0px 10px;
  font-size: medium;
  border: none;
  background: none;
  color: var(--demoqrata-zero);
  opacity: 0.8;
}

.dropdown__search form > input:hover {
  color: var(--demoqrata-four);
}

.dropdown__search form > input:hover::placeholder {
  color: var(--demoqrata-four);
}

.dropdown__search form > input:focus {
  outline-width: 0;
}

.dropdown__search form > input::placeholder {
  color: var(--demoqrata-zero);
}

::-webkit-input-placeholder {
  opacity: 0.75;
}

.dropdown__searchButton {
  background: none;
  border: none;
  color: white;
  font-size: 2em;
  opacity: 0.75;
  padding-top: 5px;
}

.dropdown__searchButton:focus {
  outline: none;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: var(--demoqrata-four);
  font-weight: bold;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--demoqrata-four);
  font-weight: bold;
}

:-ms-input-placeholder {
  color: var(--demoqrata-four);
  font-weight: lighter;
}

@media (max-width: 799px) {
  .dropdown {
    position: absolute;
    top: 170px;
    width: auto;
    
    transform: translateX(-28%);
    background-color: var(--bg);
    border: var(--border);
    border-radius: var(--border-radius);
    padding: 1rem;
    overflow: hidden;
    transition: height var(--speed) ease;
    box-shadow: rgba(255, 255, 255, 0.3) 0px 8px 24px;
  }
  
  .menu {
    width: 100%;
    height: auto;
  }
}
