.creadores__update {
  color: white;
  padding-left: 20px;
  padding-bottom: 60px;
}

.creadores__container {
  display: flex;
  justify-content: space-between;
}

.creadores__title {
  padding-left: 20px;
  padding-bottom: 20px;
}

.creadores__explora {
  opacity: 0.2;
  padding-right: 20px;
  padding-top: 10px;
}

.creadores__posters {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 20px;
}

.creadores__posters::-webkit-scrollbar {
  display: none;
}

.creadores__update__poster {
  object-fit: contain;
  max-height: 400px;
  margin-right: 0px;
  margin-left: 0px;
  cursor: pointer;
}

.creadores__poster__props {
  object-fit: contain;
  width: 100%;
  margin-right: 10px;
  transition: transform 450ms;
}

.creadores__poster__props:hover {
  transform: scale(1.09);
}
