* {
  color: white;
}

.tv {
  display: flex;
  margin: 0;
  padding: 20px;
}

.tv__player {
  flex: 0.8;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  align-self: flex-start;
  z-index: 99;
  padding-top: 100px;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.tv__player > p {
  padding: 0 0 20px 20px;
  font-size: 1.2rem;
}

.tv__player__details__content {
  padding: 20px;
}

.tv__player__details__content > h2 {
  padding: 20px 0;
  font-size: 1.8rem;
}

.tv__player__details__content__author {
  font-size: 1.2rem;
}

.tv__player__details__content__description {
  font-size: 1.2rem;
}

.tv__player__details__content__stats {
  padding: 10px 0;
  font-size: 1.2rem;
}

.tv__videolist {
  flex: 0.2;
  padding: 0 20px;
}

.tv__videolist > p {
  padding: 0 0 20px 20px;
  font-size: 1.2rem;
}

.tv__videolist__container {
  width: 90%;
  padding: 20px;
  flex-direction: column;
}

/* .tv__videolist__posters {
  overflow-y: hidden;
  overflow-x: hidden;
  transition: all ease 0.5s;
} */

/* .tv__videolist__posters::-webkit-scrollbar {
  display: none;
} */

.tv__videolist__poster {
  max-height: 200px;
  border-radius: 12px;
}

.tv__videolist__poster__props {
  max-width: auto;
  height: 400px;
  transition: transform 450ms;
}

.tv__videolist__poster__props:hover {
  transform: scale(1.05);
}

.tv__videolist__poster__author {
  max-width: 200px;
  padding-left: 20px;
  padding-top: 10px;
}

.tv__videolist__poster__details {
  max-width: 250px;
  display: inline;
  transition: all ease 0.5s;
  font-size: 0.8rem;
}

/* .tv__videolist__poster__props:hover > .tv__videolist__poster__details {
  display: inline;
} */

.tv__videolist__poster__title {
  font-weight: 400;
  padding-left: 20px;
  padding-top: 10px;
  max-width: 250px;
}

.tv__videolist__poster__stats {
  padding-left: 20px;
  max-width: 250px;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .tv {
    display: grid;
    margin: 0;
    padding: 20px;
  }

  .tv__player {
    flex: 1;
    position: -webkit-relative; /* Safari */
    position: relative;
    top: 0;
    align-self: flex-start;
    z-index: 99;
    padding-top: 20px;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .tv__videolist {
    flex: 1;
    padding: 0 20px;
  }
}
