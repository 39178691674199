* {
  margin: 0;
}

input {
  color: #111;
}

.personalize {
  color: white;
}

.personalize__container {
  display: flex;
  padding-top: 20px;
  padding-left: 40px;
}

.personalize__interestingvideos {
  margin: 0 auto;
  display: grid;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  width: 100%;
}

.personalize__keywords {
  top: 80px;
  padding: 20px 0px;
  margin-right: 20px;
  position: sticky;
  align-self: flex-start;
}

.personalize__keywords > p {
  font-weight: 600px;
  font-size: 1.5rem;
  padding: 20px 0;
}

.personalize__keywords__content > input {
  padding: 12px;
  margin: 15px 0 0 0;
  height: 12px;
  border: none;
  max-width: 600px;
  border-radius: 5px;
}

.personalize__keywords__content > input::placeholder {
  color: #111;
}

.personalize__keywords__content > button {
  padding: 10px 20px;
  margin: 10px 0 20px 0;
  font-size: 1rem;
  color: #fff;
  border-radius: 5px;
  background-color: var(--demoqrata-one);
  font-weight: 400;
  border: none;
  cursor: pointer;
}

.personalize__keywords__list > button {
  padding: 10px 20px;
  margin: 20px 0;
  font-size: 1rem;
  color: #111;
  border-radius: 5px;
  background-color: var(--demoqrata-two);
  font-weight: 400;
  border: none;
  cursor: pointer;
  max-width: 600px;
}

.personalize__keywords__content__aux {
  opacity: 0.7;
  padding: 5px 0;
}

.personalize__keywords__list > ul {
  list-style: none;
  padding: 0 20px;
}

.personalize__keywords__list ul > li:before {
  content: "#";
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -1.3em;
  width: 1.3em;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .personalize__container {
    display: grid;
    flex-direction: column;
    margin: 0;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }
  .personalize__keywords {
    top: 10px;
    position: relative;
    align-self: flex-start;
    width: 250px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  }
  .personalize__interestingvideos {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    width: 100%;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
