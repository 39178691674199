.paypalbutton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    padding-bottom: 20px;
}

.paypalbutton h2 {
    font-size: 1.6rem;
    font-weight: 700;
    justify-content: center;
    text-align: center;
    padding: 20px 0;
}

.paypalbutton__content {
    display: flex;
    justify-content: center;
    text-align: center;
}

.paypalbutton__content form button img {
    display: flex;
    width: 100px;
    align-items: center;
    margin: auto 0;
}

.paypalbutton__content form button > p {
    font-size: 1.2rem;
    font-weight: 400;
    padding: 0 20px;
    color: var(--demoqrata-zero);
}

.paypalbutton__content form {
    display: flex;
}

.paypalbutton__content form button {
    display: flex;
    background-color: transparent;
    border: solid 2px var(--demoqrata-one);
    border-radius: 20px;
    padding: 0 20px;
    color: var(--demoqrata-zero);
    cursor: pointer;
    align-items: center;
    font-size: 1.2rem;
    margin: auto 0;
}

.paypalbutton__content form button:hover {
    background-color: #332142;
}