* {
  margin: 0;
}

.search__container {
  color: white;
  background-color: #111;
}

.NoSearch__msg {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  justify-content: center;
  padding: 20px;
  align-self: flex-start;
  background-color: darkgoldenrod;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
