.paypalbox {
  display: grid;
  place-items: left;
}

.paypalbox__contents__donation > p {
  padding-top: 20px;
  color: #111;
}

.paypalbox__contents__donation input {
  padding: 12px;
  margin: 10px 0;
  height: 12px;
  border: none;
  border-radius: 5px;
  width: 250px;
  color: #111;
}
