* {
  padding: 0;
  margin: 0;
  font-family: "Rubik", sans-serif;
}

body, html {
  --demoqrata-zero: #F9F6EE;
  --demoqrata-one: #7D52A1;
  --demoqrata-two: #52a17d;
  --demoqrata-three: #56386e;
  --demoqrata-four: #111;
  --demoqrata-five: #19101F;
  --demoqrata-six: #140c18;
  --demoqrata-seven: rgba(86, 56, 110, 0.3);
  color: white;
  background-image: linear-gradient(to right, #111, black);
}

input {
 color: #111;
}

input::placeholder {
  color: #111;
 }

textarea {
  color: #111;
}

textarea::placeholder {
  color: #111;
}