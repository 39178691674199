* {
  margin: 0;
}

.creatorscreen__banner {
  position: relative;
  height: 900px;
  color: #fff;
  object-fit: contain;
  margin-bottom: 0px;
}

.creatorscreen__container {
  color: white;
  display: flex;
  padding-top: 20px;
  /* justify-content: space-between; */
}

.creatorscreen__poster {
  flex: 0.3;
  width: 30%;
  /* object-fit: contain; */
}

.creatorscreen__poster__item {
  max-height: 700px;
}

.creatorscreen__videos {
  flex: 0.7;
  width: 70%;
}

.creatorscreen__videos__item {
  display: flex;
  overflow-y: hidden;
  overflow-x: hidden;
  transition: all ease 0.5s;
}

.creatorscreen__videos__item::-webkit-scrollbar {
  display: none;
}

.creatorscreen__videos__row {
  padding-top: 40px;
  position: right;
}

.creatorscreen__videos__msg > h2 {
  padding: 0px 0px 40px 20px;
}

.row__poster {
  object-fit: contain;
  max-height: 200px;
  margin-right: 10px;
  margin-left: 10px;
}

.row__creator__props {
  height: 450px;
  transition: transform 450ms;
}

.row__creator__props:hover {
  transform: scale(1.05);
}

.row__poster__author {
  max-width: 300px;
  padding-left: 20px;
}

.row__poster__details {
  max-width: 350px;
  display: none;
  transition: all ease 0.5s;
}

.row__creator__props:hover > .row__poster__details {
  display: inline;
}

.row__poster__title {
  font-size: 1rem;
  font-weight: 400;
  padding-left: 20px;
  padding-top: 10px;
}

.row__poster__stats {
  padding-left: 20px;
}

.row__poster__left,
.row__poster__right {
  position: absolute;
  width: 40px;
  height: 160px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  transition: all ease-in-out 0.5s;
  padding-top: 20px;
  padding-bottom: 20px;
}

.row__poster__left {
  left: 0;
}

.row__poster__right {
  right: 0;
}

.creatorscreen__videos__row:hover .row__poster__left,
.creatorscreen__videos__row:hover .row__poster__right {
  opacity: 1;
}

@media (max-width: 760px) {
  .row__poster__left,
  .row__poster__right {
    opacity: 1;
  }
}
