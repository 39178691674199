* {
  padding: 0;
  margin: 0;
}

.app {
  width: 100%;
  min-height: 100vh;
  background-image: linear-gradient(to right, black, #111);
}

.app__preload {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto auto;
  position: relative;
}

.app__preload__img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto auto;
  max-width: 700px;
}

@media (max-width: 799px) {
  .app {
    width: 100%;
    height: 100vh;
  }

  .app__preload {
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 20px;
  }

  .app__preload__img {
    width: 100%;
    height: auto;
  }

}