.videoCard {
  color: white;
  width: 400px;
  height: 100%;
  transition: transform 400ms ease-in-out;
  background-color: var(--demoqrata-four);
  padding: 20px 0;
}

.videoCard:hover {
  transform: scale(1.05);
}

.videoCard > img {
  border-radius: 12px;
  display: block;
  margin: 0 auto;
  max-width: 90%;
  height: auto;
  cursor: pointer;
}

.videoCard > p {
  width: 80%;
  padding-top: 5px;
  padding-left: 20px;
}

.videoCard > h2 {
  padding-top: 10px;
  padding-left: 20px;
  width: 80%;
}

.videoCard__title {
  width: 80%;
  font-weight: 400;
  padding-left: 20px;
}

/* .videoCard__stats {
  display: none;
} */

/* .videoCard:hover > .videoCard__stats {
  display: inline;
} */

.videoCard__description {
  display: none;
}
