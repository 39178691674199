.box {
  margin: 0 auto;
  padding: 40px 20px;
  align-items: center;
  justify-content: center;
  /* align-self: flex-start; */
  display: flex;
}

.socialLinks {
  padding: 0 20px;
}
