.profile {
  color: white;
  margin: 0;
}

.profile__container {
  display: flex;
  padding: 20px;
  align-content: center;
  justify-content: center;
  margin: 0 auto;
}

.profile__data {
  /* top: 80px; */
  /* flex: 0.3; */
  margin: 0 40px;
  /* position: sticky; */
  align-self: flex-start;
  /* max-width: 275px; */
}

.profile__data > p {
  font-weight: 600px;
  font-size: 1.5rem;
  padding: 20px 0;
}

.profile__data__img {
  align-items: left;
  justify-content: left;
  display: flex;
  flex-direction: column;
}

.profile__data__img > img {
  width: 150px;
  height: 150px;
}

.profile__data__img > input {
  border-radius: 2.5px;
}

.profile__data__img > button {
  width: 100%;
  padding: 10px 2px;
  margin: 10px 0 30px 0;
  font-size: 0.9rem;
  color: #fff;
  border-radius: 5px;
  background-color: var(--demoqrata-one);
  font-weight: 400;
  border: none;
  cursor: pointer;
}

.profile__data form > ul {
  padding: 20px 0;
  width: 100%;
}

.profile__data form ul > li {
  padding: 10px 0;
  display: flex;
  width: auto;
}

.profile__data form ul li > p {
  padding-right: 5px;
}

.profile__data form ul li > input {
  padding: 7px;
  margin: 0 10px;
  height: 12px;
  width: 100%;
  border: none;
  border-radius: 5px;
}

.profile__data form ul li > input::placeholder {
  color: #111;
}

.profile__data__updated ul > button {
  width: 100%;
  padding: 20px;
  margin-top: 10px;
  font-size: 1.2rem;
  color: #fff;
  border-radius: 5px;
  background-color: var(--demoqrata-one);
  font-weight: 400;
  border: none;
  cursor: pointer;
}

input {
  color: #111;
}

.profile__interestingvideos {
  flex: 0.5;
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile__donation {
  top: 80px;
  flex: 0.2;
  padding-left: 20px;
  position: sticky;
  align-self: flex-start;
}

.profile__donation > p {
  font-weight: 600px;
  font-size: 1.5rem;
  padding: 20px 0;
}

.profile__donation__content > p {
  font-weight: 400px;
  font-size: 1.2rem;
  padding-top: 10px;
  padding-bottom: 40px;
  width: 300px;
}

.react-tabs__tab--selected {
  background: var(--demoqrata-one);
  border-color: var(--demoqrata-one);
  color: whitesmoke;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab-list {
  border-bottom: 0.5px solid var(--demoqrata-one);
  margin: 0 0 10px;
  padding: 0;
}

/* Phones and Tablet*/
@media (max-width: 999px) {
}

/* Tablet and small laptop */
@media (max-width: 999px) {
  .profile {
    display: flex;
    flex-direction: column;
    color: white;
    margin: 0;
  }
  
  .profile__container {
    display: flex;
    padding: 20px 0;
    align-content: center;
    justify-content: center;
    margin: 0 auto;
  }

  .inputTag {
    display: none;
  }

  label {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
    background-color: #111;
  } 
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
